.theme-toogle {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 4px;
    height: 20px;
    fill:  var(--primary-color);
  }
}
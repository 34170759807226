.blog-container {

  .blog-entries {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    a {
      position: relative;
      width: calc(50% - 10px);
      margin-bottom: 10px;
      img {
        width: 100%;
        object-fit: cover;
      }
      @include for-size(mobile) {
        width: 100%;
      }
    }
  }
}
@import '../fonts/';

footer {
  height: 50px;
  padding: 5px 0;
  box-sizing: border-box;
  background-color: var(--background-alt-color);
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    font-family: OpenSansCondensedExtraBold;
  }
  a + a::before {
    content: "/";
    margin: 0 5px;
    display: inline-block;
    color: var(--primary-color);
  }
}
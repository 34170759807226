@import '../constants/';
@import '../fonts/';

.about-me {
  .container {
    display: flex;
    justify-content: space-between;
    height: inherit;

    @media screen and (max-width: 949px) {
      flex-wrap: wrap;
    }

    .information {
      padding-left: 60px;
      padding-top: 93px;
      box-sizing: border-box;

      @media screen and (max-width: 949px) {
        padding-left: 20px;
        padding-top: 20px;
        padding-right: 20px;
      }
    }

    .title {
      font-family: OpenSansCondensedExtraBold;
      font-size: 48px;
      color: var(--primary-color);

      @media screen and (max-width: 500px) {
        font-size: 28px;
      }
      span.highlight {
        color: var(--highlight-color);
        font-family: OpenSansCondensedExtraBoldItalic;
      }
      span + span {
        margin-left: 10px;
      }
    }

    .description {
      color: var(--secondary-color);
    }
    
    >div {
      width: 50%;
      height: 437px;

      @media screen and (max-width: 949px) {
        width: 100%;
        height: min-content;
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 437px;
        img {
          height: 90%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
.gallery-view-container {
  color: var(--primary-color);

  .description {
    flex-direction: column;
  }

  .albums {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  section {
    margin-top: 20px;
  }
}
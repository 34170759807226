@mixin for-size($size) {
  @if ($size == desktop) {
    @media (min-width: 1200px) {
      @content; 
    }
  } @else if ($size == mobile) {
    @media (max-width: 800px) {
      @content;
    }
  }
}

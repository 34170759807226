.album {
  margin-top: 50px;
  flex-direction: column;
  

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 600px) {
      a, h4 {
        font-size: 14px !important;
        line-height: 14px !important;
      }
    }
    h4 {
      margin: 0;
      text-transform: uppercase;
      font-family: OpenSansCondensedExtraBold;
    }
  }

  &-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 10px;

    >a {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      height: 400px;
      overflow: hidden;
      cursor: pointer;
      text-decoration: none;

      &[data-ratio='landscape'] {
        width: calc(50% - 5px);
      }

      &[data-ratio='portrait'] {
        width: calc(25% - 5px);
      }

      @include for-size(mobile) {
        width: 100% !important;
        height: auto !important;
      }
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
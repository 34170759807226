@use "sass:map";
@import 'constants';
@import 'components';
@import 'layout';
@import 'views';
@import 'fonts';

body {
  margin: 0;
  font-family: OpenSans;
}

html {
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
  transition: color .3s;

  &:hover {
    cursor: pointer;
    color: var(--highlight-color);
  }
}

.app {
  min-height: 100vh;
  min-width: 320px;
  background-color: var(--background-color);
  color: var(--primary-color);
}

nav + div {
  min-height: calc(100vh - 100px);
  box-sizing: border-box;
  padding: 50px 0;
}

.app[data-theme='light'] {
  --background-color: #{map.get($light-theme, 'background' )};
  --background-alt-color: #{map.get($light-theme, 'background-alt' )};
  --primary-color: #{map.get($light-theme, 'primary' )};
  --secondary-color: #{map.get($light-theme, 'secondary' )};
  --highlight-color: #{map.get($light-theme, 'highlight' )};
  --code-color: #{map.get($light-theme, 'code-color' )};
  --code-background: #{map.get($light-theme, 'code-background' )};
}

.app[data-theme='dark'] {
  --background-color: #{map.get($dark-theme, 'background' )};
  --background-alt-color: #{map.get($dark-theme, 'background-alt' )};
  --primary-color: #{map.get($dark-theme, 'primary' )};
  --secondary-color: #{map.get($dark-theme, 'secondary' )};
  --highlight-color: #{map.get($dark-theme, 'highlight' )};
  --code-color: #{map.get($dark-theme, 'code-color' )};
  --code-background: #{map.get($dark-theme, 'code-background' )};
}
.project-card {
  position: relative;
  width: calc(25% - 10px);
  height: 100px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;

  @include for-size(mobile) {
    width: calc(50% - 5px);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  span {
    position: absolute;
    bottom: 0;
    left: 5px;
  }
}

.album-card {
  position: relative;
  width: calc(50% - 5px);
  margin-bottom: 10px;

  @include for-size(mobile) {
    width: 100%;
  }
  div {
    position: absolute;
    text-transform: uppercase;
    font-family: OpenSansCondensedExtraBold;
    bottom: 0;
    height: 100%;
    width: 100%;
    font-size: 30px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column-reverse;
    color: white;
    transition: color .2s;

    &:hover {
      color: var(--highlight-color);
    }

    @include for-size(mobile) {
      font-size: 20px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

.skelleton {
  margin-top: 30px;
  $loading-color: var(--highlight-color);
  background-color: $loading-color;
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%
  ) $loading-color;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 2s loading ease-in-out infinite;
  animation-delay: .05s;
  content: "adsd";
  display: inline-block;
}
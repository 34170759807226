.infinite-slider-container {

  .slider-button,
  %slider-button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    &:hover {
      svg {
        height: 24px;
      }
    }

    svg  {
      height: 18px;
      cursor: pointer;
      transition: height .2s;
    }
    
    &-next {
      @extend %slider-button;
    }
    &-prev {
      @extend %slider-button;
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  .slider-wrapper{
    position: relative;
    overflow: hidden;

    >div {
      display: flex;
      position: absolute;
    }
  }
}
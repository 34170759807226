.post-view-container {
  .post-content {
    p {
      text-align: justify;
      
      @include for-size(mobile) {
        text-align: left;
      };
    }
    a {
      color: var(--highlight-color);
      transition: color .3s;
      &:hover {
        color: var(--primary-color);
      }
    }

    span.underline {
      text-decoration: underline;
    }

    span.bold {
      font-weight: bold;
    }

    span.italic {
      font-style: italic;
    }

    span.code {
      padding: 0 4px;
      font-size: 13px;
      letter-spacing: 1.3px;
      background-color: var(--code-background);
      color: var(--code-color);
    }

    ul {
      padding: 0;
      p {
        margin: 0;
        margin-bottom: 2px;
      }

      li::marker {
        color: var(--highlight-color);
      }
    }

    img {
      width: 100%;
    }

    div.block {
      border-left: 6px solid var(--highlight-color);
      padding-left: 3px;
      white-space: pre-wrap;
    }
  }
}
@import '../fonts/';
@import '../constants/';

nav {
  background-color: var(--background-alt-color);
  color: var(--primary-color);
  height: 40px;
  padding: 5px 0px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: OpenSansCondensedExtraBold;

  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  a,li {
    text-decoration: none;
    color: inherit;
    transition: color .3s;
    cursor: pointer;
  
    &:hover {
      color: var(--highlight-color);
      text-decoration: underline;
    }
  }

  ul {
    all: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    list-style-type: none;
    height: 100%;

    li {
      margin-right: 40px;
      @media screen and (max-width: 600px){
        margin-right: 20px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .config {
    display: flex;
    justify-content: center;
    align-items: center;

    >.toogle-language {
      max-height: 20px;
      overflow: hidden;
      cursor: pointer; 

      >div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: OpenSansCondensedExtraBoldItalic;
        transition: transform .5s;
      }
    }
  }
} 
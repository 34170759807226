@font-face {
  font-family: OpenSansCondensedExtraBold;
  src: url('OpenSans_Condensed-ExtraBold.ttf');
}

@font-face {
  font-family: OpenSansCondensedExtraBoldItalic;
  src: url('OpenSans_Condensed-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: OpenSans;
  src: url('OpenSans-Regular.ttf');
}

h1 {
  text-transform: uppercase;
  font-family: OpenSansCondensedExtraBold;
}
